* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
}
.container {
  max-width: 1120px;
  margin: auto;
}
.video {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
a.externel-link {
  color: #000;
  font-size: 13px;
  padding-top: 10px;
}
.voice-clip-wrapper,
.photo-wrapper,
.color-wrapper,
.text-wrapper {
  margin: 20px 0;
}
.drop {
  width: 100%;
  min-height: 100px;
  padding: 30px;
  background-color: #fb6b4c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: #474747;
}
h1.soundwave-form-title {
  margin: 40px 0;
}

.title {
  padding: 20px 0;
}

.sub-title {
  padding-bottom: 20px;
}

input[type="email"] {
  width: 100%;
  height: 40px;
  padding: 4px 10px;
}
input[type="submit"] {
  background: rgb(247, 107, 28);
  color: rgb(255, 255, 255);
  font-weight: 800;
  letter-spacing: 1px;
  border: 1px solid rgb(255, 255, 255);
  width: 229px;
  padding: 8px 30px;
  border-radius: 5px;
  height: 50px;
  margin: 10px 0 100px;
  font-size: 18px;
  text-transform: uppercase;
}
.center-col {
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 992px)
.col-md-6 {
  width: 50%;
}